@font-face {
  font-family: 'Premia';
  font-weight: 400;
  src: url('https://com.airpremiacdn.net/resources/font/Premia-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Premia';
  font-weight: 700;
  src: url('https://com.airpremiacdn.net/resources/font/Premia-Medium.woff2') format('woff2');
}


@font-face {
  font-family: "NotoSans";
  font-weight: 100;
  src: url("https://com.airpremiacdn.net/resources/font/NotoSans-Thin.woff2") format("woff2")
}

@font-face {
  font-family: "NotoSans";
  font-weight: 200;
  src: url("https://com.airpremiacdn.net/resources/font/NotoSans-Light.woff2") format("woff2")
}

@font-face {
  font-family: "NotoSans";
  font-weight: 300;
  src: url("https://com.airpremiacdn.net/resources/font/NotoSans-DemiLight.woff2") format("woff2")
}

@font-face {
  font-family: "NotoSans";
  font-weight: 400;
  src: url("https://com.airpremiacdn.net/resources/font/NotoSans-Regular.woff2") format("woff2")
}

@font-face {
  font-family: "NotoSans";
  font-weight: 500;
  src: url("https://com.airpremiacdn.net/resources/font/NotoSans-Medium.woff2") format("woff2")
}

@font-face {
  font-family: "NotoSans";
  font-weight: 700;
  src: url("https://com.airpremiacdn.net/resources/font/NotoSans-Bold.woff2") format("woff2")
}

@font-face {
  font-family: "NotoSans";
  font-weight: 800;
  src: url("https://com.airpremiacdn.net/resources/font/NotoSans-Black.woff2") format("woff2")
}

@font-face {
  font-family: "Koruri";
  font-weight: 300;
  src: url("https://com.airpremiacdn.net/resources/font/Koruri-Light.woff2") format("woff2")
}

@font-face {
  font-family: "Koruri";
  font-weight: 400;
  src: url("https://com.airpremiacdn.net/resources/font/Koruri-Regular.woff2") format("woff2")
}

@font-face {
  font-family: "Koruri";
  font-weight: 500;
  src: url("https://com.airpremiacdn.net/resources/font/Koruri-Semibold.woff2") format("woff2")
}

@font-face {
  font-family: "Koruri";
  font-weight: 700;
  src: url("https://com.airpremiacdn.net/resources/font/Koruri-Bold.woff2") format("woff2")
}

@font-face {
  font-family: "Koruri";
  font-weight: 800;
  src: url("https://com.airpremiacdn.net/resources/font/Koruri-Extrabold.woff2") format("woff2")
}

@font-face {
  font-family: "Heebo";
  font-weight: 400;
  src: url("https://com.airpremiacdn.net/resources/font/Heebo-Regular.woff2") format("woff2");
  unicode-range: U+0020-007E;
}
@font-face {
  font-family: "Heebo";
  font-weight: 700;
  src: url("https://com.airpremiacdn.net/resources/font/Heebo-Medium.woff2") format("woff2");
  unicode-range: U+0020-007E;
}

@font-face {
  font-family: 'Pretendard JP';
  font-weight: 900;
  font-display: swap;
  src: url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff2/PretendardJP-Black.woff2) format('woff2'), url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff/PretendardJP-Black.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard JP';
  font-weight: 800;
  font-display: swap;
  src: url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff2/PretendardJP-ExtraBold.woff2) format('woff2'), url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff/PretendardJP-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard JP';
  font-weight: 700;
  font-display: swap;
  src: url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff2/PretendardJP-Bold.woff2) format('woff2'), url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff/PretendardJP-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard JP';
  font-weight: 600;
  font-display: swap;
  src: url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff2/PretendardJP-SemiBold.woff2) format('woff2'), url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff/PretendardJP-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard JP';
  font-weight: 500;
  font-display: swap;
  src: url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff2/PretendardJP-Medium.woff2) format('woff2'), url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff/PretendardJP-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard JP';
  font-weight: 400;
  font-display: swap;
  src: url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff2/PretendardJP-Regular.woff2) format('woff2'), url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff/PretendardJP-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard JP';
  font-weight: 300;
  font-display: swap;
  src: url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff2/PretendardJP-Light.woff2) format('woff2'), url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff/PretendardJP-Light.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard JP';
  font-weight: 200;
  font-display: swap;
  src: url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff2/PretendardJP-ExtraLight.woff2) format('woff2'), url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff/PretendardJP-ExtraLight.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard JP';
  font-weight: 100;
  font-display: swap;
  src: url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff2/PretendardJP-Thin.woff2) format('woff2'), url(https://com.airpremiacdn.net/resources/font/pretendard-jp/woff/PretendardJP-Thin.woff) format('woff');
}
